import { ApplicationController } from "./application_controller.js"
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import Tus from '@uppy/tus'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
export default class extends ApplicationController   {
  static targets = [ 'zone', 'attachment','uploads' ]
  connect() {
    // console.log('hello from uppy', this.element)
    // console.log([this.userSgid, this.organizationSgid])
    var zoneStyle = window.getComputedStyle(this.element)
    // console.log('zone style is', zoneStyle)
    // console.log('zone target is',this.zoneTarget)
    if (!this.hasZoneTarget) {
      return true
    }
    var uppy = Uppy({
      autoProceed: true,
      allowMultipleUploads: true,
      debug: true,
      meta: {
        user: this.userSgid,
        organization: this.organizationSgid,
        attachment: this.attachment
      },
      onBeforeFileAdded: (currentFile, files) => {
        console.log(currentFile, files);
        return true;
      }
    })
    .on('error', (error) => {
      if (window.Rollbar){
        window.Rollbar.error("Uppy upload error", error);
      }
    })
    .on('upload-error', (file, error, response) => {
      if (window.Rollbar){
        window.Rollbar.error("Uppy upload-error", error);
      }
    })
    .on('upload', (data) => {
      console.log("upload!", data)
    })
    .on('upload-success', (response) => {
      console.log("upload-success!", response)
    })
    .on('complete', (result) => {
      console.log("complete!",result)
      result.successful.forEach((file, i) => {
        var filedata = {
          upload_url: file.uploadURL,
          name: file.name,
          type: file.type,
          size: file.size,
          metadata: file.meta,
          visibility: 'public',
        }
        fetch('/blob/upload.json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-Csrf-Token': this.csrfToken,
            'Accept': 'application/json',
          },
          body: JSON.stringify(filedata),
          credentials: 'same-origin',
        }).then((response) => {
          console.log('parsed!')
          // response returns html...
          return response.json()
        }).then((json) => {
          var htmlUrl = json.urls.parentHtml
          fetch(htmlUrl, {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
            },
            credentials: 'same-origin',
          })
          .then((response) => {
            return response.text()
          }).then((html) => {
            if (this.hasUploadsTarget){
              console.log('replaced!')
              this.uploadsTarget.innerHTML = html;              
            }
          })
        })
      })
    })
    .use(Dashboard, {
      inline: true,
      target: this.zoneTarget,
      width: parseInt(zoneStyle.width),
      height: Math.max(parseInt(window.innerHeight / 2.5),200),
      showLinkToFileUploadResult: false,
      hideProgressAfterFinish: true,
      proudlyDisplayPoweredByUppy: false,
    })
    .use(AwsS3Multipart, {companionUrl: '/'})
  }

  get attachment(){
    try {
      return this.element.dataset.attachedTo
    } catch (e) {
      return null
    }
  }

}



/*

  stimulus: define controller as the uppy dropzone and the list of uploads
    uppy.zone: dropzone
    uppy.uploads: list of uploads

  on successful upload:
    post to blob/uploads
    get success reponse back
    grab the parent URL
    get the HTML
    replace uppy.uploads with

*/
