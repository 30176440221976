import { Controller } from "@hotwired/stimulus"

export class ApplicationController extends Controller {

  // hide by adding the 'hidden' class
  hideElement(el){
    this.addClass(el,"hidden")
  }

  // show by removing the 'hidden' class
  showElement(el){
    this.removeClass(el,"hidden")
  }

  addClass(el,className){
    if (el.classList)
      el.classList.add(className);
    else
      el.className += ' ' + className;
  }

  removeClass(el,className){
    if (el.classList)
      el.classList.remove(className);
    else
      el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
  }

  // get rails CSRF token from meta tag
  get csrfToken(){
    return document.head.querySelector("meta[name='csrf-token'][content]").content
  }

  get userSgid(){
    return document.querySelector('meta[property="user-sgid"]')
                                         .getAttribute("content")
  }
  get organizationSgid(){
    return document.querySelector('meta[property="organization-sgid"]')
                                         .getAttribute("content")
  }


}
